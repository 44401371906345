import React from 'react';
import rubble from '../image/rubble.svg';

function Payment(props) {

    return (
        <section className='payment'>
            <img src={rubble} className="payment__logo" alt="logo" />
            <div className='payment__information'>
                <p className='payment__description'>Способы оплаты:</p>
                <ul className='payment__list'>
                    <li className='payment__element'>100% оплата</li>
                    <li className='payment__element'>Сельская ипотека  3% годовых: на участки, готовые и строящиеся дом (первоначальный взнос не менее 20,1%)</li>
                    <li className='payment__element'>Рассрочка</li>
                </ul>
            </div>
        </section>
    )
}

export default Payment;