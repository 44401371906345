const textSemidvorie = [
    {
        id: 0,
        text: "Современная планировка",
    },
    {
        id: 1,
        text: "Окна в пол",
    },
    {
        id: 2,
        text: "На 1-м этаже: просторная кухня-гостиная 36 кв.м. гостевой с/у, гардеробная и котельная. На 2-м этаже: 3 спальни, с/у",
    },
    {
        id: 3,
        text: `Внутренние работы: выполнены штукатурка стен, стяжка полов, по дому  разведены коммуникации (вода, электричество, тепло)`,
    }
];

const textSemidvorie2 = [
    {
        id: 40,
        text: "Общая площадь",
    },
    {
        id: 41,
        text: "112 кв.м.",
    },
    {
        id: 42,
        text: "Количество этажей",
    },
    {
        id: 43,
        text: "2",
    },
    {
        id: 44,
        text: "Количество комнат"
    },
    {
        id: 45,
        text: "4",
    },
    {
        id: 46,
        text: "В т.ч. количество спален",
    },
    {
        id: 47,
        text: "3"
    },
    {
        id: 48,
        text: "Гардеробная",
    },
    {
        id: 49,
        text: "1"
    },
    {
        id: 50,
        text: "Количество с/у"
    },
    {
        id: 51,
        text: "2"
    },
    {
        id: 52,
        text: "Перекрытия"
    },
    {
        id: 53,
        text: "монолит"
    },
    {
        id: 54,
        text: "Стены"
    },
    {
        id: 55,
        text: "газобетонные блоки"
    },
    {
        id: 56,
        text: "Фасад"
    },
    {
        id: 57,
        text: "штукатурка"
    },
    {
        id: 58,
        text: "Отопление дома"
    },
    {
        id: 59,
        text: "газовый котел"
    },
    {
        id: 60,
        text: "Парковка"
    },
    {
        id: 61,
        text: "плоскостная"
    },
    {
        id: 62,
        text: "Размеры участка"
    },
    {
        id: 63,
        text: "4-11 соток"
    }
];

const textHouse1 = [
    {
        id: 10,
        text: "Площадь: 32 кв.м.",
    },
    {
        id: 11,
        text: "Уютные дома в стиле Барнхаус на огороженной благоустроенной территории.",
    },
    {
        id: 12,
        text: "кухня-гостиная, спальня, с/у",
    },
    {
        id: 13,
        text: "Площадь земельного участка 10-12 соток.",
    },
    {
        id: 14,
        text: "Назначение ИЖС."
    },
    {
        id: 15,
        text: "Стоимость: 5 000 000",
    }
];

const textHouse2 = [
    {
        id: 20,
        text: "Площадь: 40 кв.м.",
    },
    {
        id: 21,
        text: "Уютные дома в стиле Барнхаус на огороженной благоустроенной территории.",
    },
    {
        id: 22,
        text: "кухня-гостиная, спальня, с/у",
    },
    {
        id: 23,
        text: "Площадь земельного участка 10-12 соток.",
    },
    {
        id: 24,
        text: "Назначение ИЖС."
    },
    {
        id: 25,
        text: "Стоимость: 5 000 000",
    }
];

const textHouse3 = [
    {
        id: 30,
        text: "Площадь: 55 кв.м.",
    },
    {
        id: 31,
        text: "Уютные дома в стиле Барнхаус на огороженной благоустроенной территории.",
    },
    {
        id: 32,
        text: "просторная кухня, гостиная, 2 спальни, с/у",
    },
    {
        id: 33,
        text: "Площадь земельного участка 10-12 соток.",
    },
    {
        id: 34,
        text: "Назначение ИЖС."
    },
    {
        id: 35,
        text: "Стоимость: 5 000 000",
    }
];

export { textSemidvorie, textSemidvorie2, textHouse1, textHouse2, textHouse3 };