import React from 'react';
import Developer from './Developer';
import semidvirie from '../image/Semidvorie/House1.png';
import shablykino from '../image/Shablykino/House1/Shablykino.jpeg';
import { useNavigate } from 'react-router-dom';

function Main() {
    const navigate = useNavigate();
    function handleClickSemidvorie() {
        navigate('/semidvorie');
    }

    function handleClickShablykino() {
        navigate('/shablykino-village');
    }

    return (
        <main className="main">
            <section className="houses">
                <div className="house">
                    <img className="house__foto" src={semidvirie}  onClick={handleClickSemidvorie} alt="Подождите немного и фотография загрузится" />
                    <div className="house__description">
                        <h2 className="house__title">Семидворье</h2>
                        <p className="house__text">30 км от Москвы (дп Зеленоградский Пушкинский р-н Московской обл.).</p>
                        <p className="house__text">Развитая инфраструктура, экология и оптимальная отдаленность от города, делают Семидворье привлекательным для постоянного проживания. Граничит с Храмом Сергия Радонежского, в шаговой доступности лесной массив.</p>
                    </div>
                    <button onClick={handleClickSemidvorie} className="house__buttom">Подробнее</button>
                </div>
                <div className="house">
                    <img className="house__foto" src={shablykino} onClick={handleClickShablykino} alt="Подождите немного и фотография загрузится"/>
                    <div className="house__description">
                        <h2 className="house__title">Шаблыкино-3</h2>
                        <p className="house__text">90 км от Москвы (дер. Шаблыкино Александровский р-н Владимирской обл.).</p>
                        <p className="house__text">Деревня с восхитительными природными пейзажами и прекрасной экологией. Все располагает для размеренного времяприровождения семьи вдали от городской суеты, в качестве загородного дома или постоянного местожительства.</p>
                    </div>
                    <button onClick={handleClickShablykino} className="house__buttom">Подробнее</button>
                </div>
            </section>
            <Developer />
      </main>
    )
}

export default Main;