import React from 'react';
const MAX_MOBILE_WIDTH = 770;

function Developer() {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= MAX_MOBILE_WIDTH)
    const prevWidth = React.useRef(window.innerWidth);

    React.useEffect(() => {
        const handleResize = () => {
          const currWidth = window.innerWidth
          if (currWidth <= MAX_MOBILE_WIDTH && prevWidth.current > MAX_MOBILE_WIDTH){
            setIsMobile(true)
          } else if (currWidth > MAX_MOBILE_WIDTH && prevWidth.current <= MAX_MOBILE_WIDTH) {
            setIsMobile(false)
          }
          prevWidth.current = currWidth
        }
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    let header;

    return (
        <>
            {
                isMobile ? header = (
                    <>
                        <section className="developer">
                            <h2 className="developer__title">О застройщике</h2>
                            <div className="developer__texts">
                                <p className="developer__text">Компания «Центр-2000» существует с 1988 года, а с 1991 года она вышла на рынок недвижимости. </p>
                                <p className="developer__text">В настоящее время Компания осуществляет управление строительными  проектами, расположенными в Москве и Московской области.</p>
                                <p className="developer__text">С 1991 года Компания приняла активное участие в реализации более 20 инвестиционных проектов самого разнообразного назначения, включая строительство жилой и коммерческой недвижимости.</p>
                                <p className="developer__text">Имея необходимый опыт, Компания «Центр-2000» способна обеспечить весь цикл инвестиционного строительного проекта собственными ресурсами.</p>
                                <p className="developer__text">Компания «Центр-2000» за годы своей профессиональной деятельности на рынке недвижимости была отмечена многочисленными наградами. Руководство фирмы «Центр-2000» удостоено званий «Заслуженный строитель России» и «Почетный строитель Москвы».</p>
                                <p className="developer__text">Фирма неоднократно получала Дипломы Правительства Москвы по результатам конкурсов — за лучшую реконструкцию и строительство в исторической части Москвы.</p>
                            </div>
                        </section>
                    </>
                ) : header = (
                    <>
                        <section className="developer">
                            <h2 className="developer__title">О застройщике</h2>
                            <div className="developer__descriptions">
                                <div className="developer__texts">
                                    <p className="developer__text">Компания «Центр-2000» существует с 1988 года, а с 1991 года она вышла на рынок недвижимости. </p>
                                    <p className="developer__text">В настоящее время Компания осуществляет управление строительными  проектами, расположенными в Москве и Московской области.</p>
                                    <p className="developer__text">С 1991 года Компания приняла активное участие в реализации более 20 инвестиционных проектов самого разнообразного назначения, включая строительство жилой и коммерческой недвижимости.</p>
                                </div>
                                <div className="developer__texts">
                                    <p className="developer__text">Имея необходимый опыт, Компания «Центр-2000» способна обеспечить весь цикл инвестиционного строительного проекта собственными ресурсами.</p>
                                    <p className="developer__text">Компания «Центр-2000» за годы своей профессиональной деятельности на рынке недвижимости была отмечена многочисленными наградами. Руководство фирмы «Центр-2000» удостоено званий «Заслуженный строитель России» и «Почетный строитель Москвы».</p>
                                    <p className="developer__text">Фирма неоднократно получала Дипломы Правительства Москвы по результатам конкурсов — за лучшую реконструкцию и строительство в исторической части Москвы.</p>
                                </div>
                            </div>
                        </section>
                    </>
                )
            }
        </>
    );
}

export default Developer;